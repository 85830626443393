import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Section from '@components/Content/Section/Section'

import * as styles from './QuoteCTA.module.scss'

interface Props {
	type?: 'default' | 'widget'
}

const QuoteCTA = (props: Props) => {
	const backgroundImage = <StaticImage src="../../../images/homepage-hero.jpg" alt="" />

	const classNames = [styles.cta]

	if (props.type === 'widget') {
		classNames.push(styles.isWidget)
	}

	return (
		<Section
			className={classNames.join(' ')}
			containerClassName={styles.container}
			backgroundImage={backgroundImage}
		>
			<div className={styles.left}>
				<h2 className={styles.title}>Ready, set, go!</h2>
				<p className={styles.content}>Applying with us only takes a couple of minutes.</p>
			</div>
			<div className={styles.right}>
				<Button uri="/apply" label="Get your quote" className={styles.button} />
			</div>
		</Section>
	)
}

QuoteCTA.defaultProps = {
	type: 'default',
}

export default QuoteCTA
