import React from 'react'

import QuoteCTA from '@components/Content/QuoteCTA/QuoteCTA'
import Section from '@components/Content/Section/Section'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import Wysiwyg from '@components/Layout/Wysiwyg'
import LendersFeatured from '@components/Lenders/LendersFeatured'
import LendersGrid from '@components/Lenders/LendersGrid'

import { WpLendersArchiveProps } from '@contracts/lenders'

import * as styles from './LendersArchive.module.scss'

const LendersArchive = (props: WpLendersArchiveProps) => {
	const { lenders, page } = props.pageContext.data

	return (
		<Layout>
			<Section className={styles.intro} noPaddingBottom>
				<h1 className={styles.title}>{page.title}</h1>
				<Wysiwyg className={styles.subtext} content={page.content} />
			</Section>

			<Section noPaddingBottom>
				<LendersFeatured />
			</Section>

			<Section>
				<LendersGrid lenders={lenders} />
			</Section>

			<QuoteCTA />
		</Layout>
	)
}

export function Head(props: WpLendersArchiveProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.page.seo} />
		</>
	)
}

export default LendersArchive
