import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Wysiwyg from '@components/Layout/Wysiwyg'

import { FeaturedLendersQueryProps } from '@contracts/lenders'

import * as styles from './LendersFeatured.module.scss'

const LendersFeatured = () => {
	const data: FeaturedLendersQueryProps = useStaticQuery(graphql`
		query FeaturedLendersQuery {
			wp {
				lendersSettings {
					lendersOptions {
						featuredLenders {
							content
							lender {
								... on WpLender {
									id
									title
									featuredImage {
										node {
											localFile {
												childImageSharp {
													gatsbyImageData(height: 80)
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const featuredLenders = data.wp.lendersSettings.lendersOptions.featuredLenders

	return (
		<ul className={styles.lenders}>
			{featuredLenders.map((lender) => {
				return (
					<li key={lender.lender.title} className={styles.lender}>
						<div className={styles.imageContainer}>
							{lender.lender.featuredImage.node.localFile && (
								<GatsbyImage
									image={lender.lender.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
									className={styles.image}
									alt={lender.lender.title}
								/>
							)}
						</div>
						<h3 className={styles.title}>{lender.lender.title}</h3>
						<Wysiwyg content={lender.content} className={styles.content} />
					</li>
				)
			})}
		</ul>
	)
}

export default LendersFeatured
