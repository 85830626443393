import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import { LenderProps } from '@contracts/lenders'

import * as styles from './LendersGrid.module.scss'

interface Props {
	lenders: LenderProps[]
}

const LendersGrid = (props: Props) => {
	const { lenders } = props

	// Split renders into arrays of 5
	const renderLenders = lenders.reduce((acc: Array<LenderProps[]>, curr, index) => {
		const chunkIndex = Math.floor(index / 5)

		if (!acc[chunkIndex]) {
			acc[chunkIndex] = []
		}

		acc[chunkIndex].push(curr)

		return acc
	}, [])

	return (
		<table className={styles.table}>
			<tbody>
				{renderLenders.map((lenders, index) => (
					<tr key={`lender-row-${index}`}>
						{lenders.map((lender) => {
							if(lender.featuredImage.node.localFile) {
								return (
									<td key={lender.title}>
										<GatsbyImage
											image={lender.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
											className={styles.image}
											alt={lender.title}
											title={lender.title}
										/>
									</td>
								)
							} else {
								return null
							}
						})}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default LendersGrid
